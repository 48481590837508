import React, { useEffect, useState } from 'react';
import { Button, List, Modal, Card, Descriptions, Divider, Tag, Image, Badge, Alert, Avatar, message } from 'antd';
import { Row, Col } from "react-bootstrap"
import { FetchAllOrderDetails } from '../service/APIService';
import { useAuth } from '../authentication/context/screenContext';
import '../style/Style.css'
import { FaUserCircle } from 'react-icons/fa';
import { FaUser } from 'react-icons/fa6';


const OrderViewDetails = ({ orderId }) => {
    const { token, logout } = useAuth()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [initLoading, setInitLoading] = useState(true);
    const [orderData, setOrderData] = useState([])

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const ShowOrderDetails = async () => {
        try {
            await FetchAllOrderDetails(token, orderId)
                .then((res) => {
                    console.log("order detaisl", res);
                    if (res.status == 200) {
                        setOrderData(res.data.data)
                        setInitLoading(false)
                    } else if (res.data.code == 283) {
                        message.error(res.data.message)
                        logout()
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);
            setInitLoading(false)
        }

        showModal();
    };

    useEffect(() => {
        //  ShowOrderDetails()
    }, [])


    const getStatusColor = (status) => {
        switch (status) {
            case 'ONGOING':
                return '#5AB2FF';
            case 'UPCOMING':
                return '#FF7D29';
            case 'COMPLETED':
                return '#87A922';

            default:
                return '#B3C8CF';
        }
    };


    return (
        <>
            <div className="text-center">
                <Button type="link" id='View_details' onClick={() => ShowOrderDetails(orderId)}>View Details</Button>
            </div>

            <Modal width={700} title="Booking Information" footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="show_biiking_detials">
                    <List
                        className="demo-loadmore-list"
                        itemLayout="horizontal"
                        dataSource={orderData?.serviceInfo}
                        loading={initLoading}
                        renderItem={(item) => (
                            <List.Item
                                actions={[<span><Badge
                                    className="site-badge-count-109"
                                    count={item.selectedValue}
                                    style={{
                                        backgroundColor: '#FF0080',
                                    }}
                                /></span>, <b>₹{item.price}</b>]}
                            >

                                <List.Item.Meta
                                    avatar={<Image src={item.serviceImage} width={40} height={40} />}
                                    title={<div>
                                        <p>{item.serviceName}  </p>
                                    </div>}
                                    description={<div>
                                        <p>2 hours</p>


                                    </div>}
                                />

                            </List.Item>
                        )}
                    />
                    <Divider dashed />
                    <div className="details_summary">
                        <Row>
                            <Col md={6}>
                                <Card title="Booking Details" size='small'>
                                    <Descriptions  >


                                        <Descriptions.Item label="Payment Status" span={3}><Tag color='blue'>
                                            Cash  </Tag></Descriptions.Item>
                                        <Descriptions.Item label="Booking Status" span={3}><Tag key={orderData?._id} color={getStatusColor(orderData?.status)}>
                                            {orderData?.status}</Tag></Descriptions.Item>
                                        {
                                            orderData.cancelRemark &&
                                            <Descriptions.Item label="Cancelled Reason" span={5}><Tag key={orderData?._id} >
                                                {orderData?.cancelRemark.map((item,i) => {return <div style={{margin:"5px"}}>{item}{orderData.cancelRemark.length != i+1 ? "," : ""}</div>})}</Tag></Descriptions.Item>
                                        }


                                        <Descriptions.Item label="Booking Slot" span={3}>
                                            {orderData?.serviceDateTime}</Descriptions.Item>
                                        <Descriptions.Item label="Vendor Name  " span={3}>{orderData?.vendorFullName != null ? orderData?.vendorFullName : "N/A"}</Descriptions.Item>
                                        <Descriptions.Item label="Vendor Number  " span={3}>{orderData?.vendorMobileNo != null ? orderData?.vendorMobileNo : "N/A"}</Descriptions.Item>
                                        <Descriptions.Item label="Vendor Image  " span={3}>
                                            {orderData?.
                                                vendorProfilePic != null ? <Image src={orderData?.
                                                    vendorProfilePi} width={40} height={40} /> : <Avatar icon={<FaUser />} size={45} />}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Location  " span={3}>{orderData?.userInfo?.
                                            userLocation
                                        }</Descriptions.Item>


                                    </Descriptions>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card title="Bill Details" size='small'>
                                    <div className="pay_summer">
                                        <div className="pay_sum_item">
                                            <p>Item Total</p>
                                            <p>₹ {orderData?.totalServicePrice}</p>
                                        </div>
                                        <Alert showIcon={false} type='error' message={<div className="pay_sum_item">
                                            <p>Gst ({orderData?.gst}%)</p>
                                            <p>₹ {orderData?.gstAmount}</p>
                                        </div>} banner />

                                        {orderData?.discountAmount != null ?
                                            <Alert showIcon={false} type='success' message={<div className="pay_sum_item">
                                                <p>Discount</p>
                                                <p>₹ 0</p>
                                            </div>} banner />
                                            : null}
                                        <Divider dashed />
                                        <div className="pay_sum_item">
                                            <p><b>Payable Amount</b></p>
                                            <p><b>₹ {orderData?.userPayableAmount}</b></p>
                                        </div>

                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default OrderViewDetails