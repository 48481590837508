import React from "react";
import { Collapse } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

const HomeFaqCard = () => {
  const { Panel } = Collapse;

  const genExtra = (isActive) =>
    isActive ? <MinusOutlined /> : <PlusOutlined />;

  return (
    <section className="home-faq-section-item">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>FAQs</h2>
        </div>
        <div className="faq-grid">
          <Collapse
            accordion
            expandIconPosition="right"
            expandIcon={({ isActive }) => genExtra(isActive)}
            className="faq-panel"
          >
            <Panel
              header="What is the best way to take care of my nails after getting them done?"
              key="1"
              className="custom-panel-header"
            >
              <p>
              After getting your nails done, it's best to avoid activities that could potentially damage them, such as opening cans or using your nails as tools. It's also important to keep your nails moisturized with cuticle oil or lotion to prevent them from becoming dry and brittle.
              </p>
            </Panel>
            <Panel
              header="How often should I come in for nail art services or treatments?"
              key="2"
              className="custom-panel-header"
            >
              <p>
              The frequency of nail art services or treatments largely depends on personal preference and the condition of your nails. For some people, monthly appointments may be sufficient, while others may prefer to come in every two weeks. We recommend discussing with your technician to determine what works best for you.
              </p>
            </Panel>
            <Panel
              header="Can I bring in my own design or do you have pre-set designs to choose from?"
              key="2"
              className="custom-panel-header"
            >
              <p>
              we love helping our clients express their unique style through their nails. We offer a variety of pre-set designs to choose from, but we're also happy to work with you to create a custom design that suits your preferences.
              </p>
            </Panel>
          </Collapse>
          <Collapse
            accordion
            expandIconPosition="right"
            expandIcon={({ isActive }) => genExtra(isActive)}
            className="faq-panel"
          >
            <Panel
              header="What types of products do you use, and are they safe for my nails?"
              key="3"
              className="custom-panel-header"
            >
              <p>
              We use only the highest-quality, professional-grade products in our nail art services and treatments. Our products are safe and gentle for your nails, and we take care to ensure that they are properly sanitized between uses.
              </p>
            </Panel>
            <Panel
              header="Do you offer group packages or party services for events like weddings or birthdays?"
              key="4"
              className="custom-panel-header"
            >
              <p>
              Yes, we offer group packages and party services for events such as weddings, birthdays, and other special occasions. Please contact us to discuss your needs and to learn more about our group rates and packages.
              </p>
            </Panel>
            {/* <Panel
              header="What if I disagree with the closure of a question? How to reopen it?"
              key="5"
              className="custom-panel-header"
            >
              <p>
                Steps to take if you disagree with the closure of a question and
                how to reopen it.
              </p>
            </Panel> */}

            {/* <Panel
              header="What if I disagree with the closure of a question? How to reopen it?"
              key="6"
              className="custom-panel-header"
            >
              <p>
                Steps to take if you disagree with the closure of a question and
                how to reopen it.
              </p>
            </Panel> */}
          </Collapse>
        </div>
      </div>
    </section>
  );
};

export default HomeFaqCard;
