import React, { useEffect, useState } from 'react'
import { Card, List, Radio, Button, Divider, Form, Input, Alert, Image } from "antd"
import "../style/Style.css"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { addItem, removeItem, selectCartItems, selectTotalAmount } from '../redux/CartSlice'
import { selectDiscount, selectDiscountAmount, selectDiscountValue, selectSetting, selectUserLocation, setDiscountAmount, setDiscountDetails, setDiscountValue, setGstAmount, setPaybleAmount, setTotalAmount } from '../redux/OrderSlice'
import { FetchTodayDiscount } from '../service/APIService'
import { useAuth } from '../authentication/context/screenContext'
import Logo from "../assest/logo.png"

function PaymentSummary() {
  const { token } = useAuth()
  const [applyCoupon, setApplyCoupon] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cartItems = useSelector(selectCartItems);
  const userLocation = useSelector(selectUserLocation);
  const totalAmount = useSelector(selectTotalAmount);
  const disValue = useSelector(selectDiscountValue);
  const disAmount = useSelector(selectDiscountAmount);
  const discountAmount = useSelector(selectDiscountAmount);
  const settings = useSelector(selectSetting);
  const getGst = useSelector(selectSetting);
  const [gstValue, setgstValue] = useState(0)

  const [discountInfo, setDiscountInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [payAmount, setPayAmount] = useState(0)
  const [SelectedCoupon, setSelectedCoupon] = useState("")






  const totalAmounthandle = () => {
    // const dis = Math.round((totalAmount / 100) * 10)
    const gsts = (totalAmount / 100) * settings?.detials?.gst
    const dis = (totalAmount / 100) * disValue
    const discountedAmt = (totalAmount - dis)
    const payAmtWithDiscount = (discountedAmt / 100) * settings?.detials?.gst

    console.log("discountedAmt", discountedAmt)


    dispatch(setDiscountAmount(dis))
    // const payamt = Math.round((dis / 100) * settings?.detials?.gst)
    setgstValue(gsts)
    dispatch(setGstAmount(gsts))
    dispatch(setTotalAmount(totalAmount))
    if (applyCoupon != true) {
      setPayAmount(totalAmount + gsts)
      dispatch(setPaybleAmount(totalAmount + gsts))

    } else {

      setgstValue(payAmtWithDiscount)
      dispatch(setGstAmount(payAmtWithDiscount))
      setPayAmount(totalAmount - dis + payAmtWithDiscount)
      dispatch(setPaybleAmount(totalAmount - dis + payAmtWithDiscount))

    }


  }


  useEffect(() => {
    totalAmounthandle()
  }, [totalAmount, cartItems, discountAmount, settings])






  const showAllDiscount = async () => {
    try {
      await FetchTodayDiscount(token)
        .then((res) => {
          console.log(" discount list", res);
          if (res.status == 200) {
            setDiscountInfo(res.data.data);
            setIsLoading(true)
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (error) {
      console.log(error);
      setIsLoading(true)
    }
  };

  useEffect(() => {
    showAllDiscount()
  }, [])

  const handleCoupon = (item) => {
    setSelectedCoupon(item._id)
    setApplyCoupon(true)
    dispatch(setDiscountDetails(item))
    dispatch(setDiscountValue(item.discountValue))
    dispatch(setDiscountAmount(item.discountAmount))
    console.log("item.discountValue", item.discountValue)
    console.log("item.discountamt", item.discountAmount)
  }
  const onClose = (e) => {
    setApplyCoupon(false)
    setSelectedCoupon("")
    dispatch(setDiscountDetails(null))
    dispatch(setDiscountValue(0))
    dispatch(setDiscountAmount(0))
  };

  const handleAddItem = (item) => {
    dispatch(addItem(item))

  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartItems));
  }, [cartItems]);




  const handleDelItem = (item) => {
    dispatch(removeItem(item));
    const cart = JSON.parse(localStorage.getItem("cart")) || []; // Retrieve cart from localStorage
    const updatedCart = cart.filter(cartItem => cartItem._id !== item._id); // Filter out the item to be removed
    localStorage.setItem("cart", JSON.stringify(updatedCart))

  };


  return (
    <div className='cart_setions'>
      <div className="cart_item">
        <Card title={`My Cart  (${cartItems.length})`}>

          <div className="carat_item_list">
            {
              cartItems.length > 0 ?
              <List
              itemLayout="horizontal"
              dataSource={cartItems}

              renderItem={(item) => (
                <List.Item
                  actions={[<b>₹ {item.price}</b>,]}
                >
                  <List.Item.Meta
                    avatar={<Image src={item.serviceImage} width={50} height={50} style={{ borderRadius: "8px" }} />}
                    title={item.serviceName}
                    description={<div>
                      <p>{item.timeDuration} . {item.timeUnit} </p>
                    </div>}
                  />


                  <Button.Group>
                    <Button onClick={() => handleDelItem(item)}> -</Button>
                    <Button> {item.unit}</Button>
                    <Button onClick={() => handleAddItem(item)}> +</Button>
                  </Button.Group>
                </List.Item>
              )}
              />
              :
            <div style={{display:"flex",justifyContent:"center"}}>
              <div>
                <div style={{display:"flex",flexDirection:"column",justifySelf:"center"}}>
                    <p >No items found</p>
                    <Button onClick={() => navigate('/')}>Start shopping</Button>
              </div>
              </div>
            </div>
            
            }
            
          </div>
        </Card>
      </div>
      <div className="offer_apply">
        {discountInfo?.length != 0 ?
          <Card className={applyCoupon == true && "show_firework"}>

            <div class=" row">
              {discountInfo.map((item) => (
                <div class="col-md-6">
                  <div class={`coupon p-3 bg-white ${SelectedCoupon === item._id && "selectCoupon"}`} onClick={() => handleCoupon(item)}>
                    <div class="row no-gutters">
                      <div class="col-md-4 border-right">
                        <div class="d-flex flex-column align-items-center">
                          <img src={Logo} id='discount_logo' />
                        </div>
                      </div>
                      <div class="col-md-8">

                        <div class="d-flex flex-row justify-content-end off"> <h1>{item.discountValue}%</h1><span>OFF</span>
                        </div>

                      </div>
                      <div class="d-flex flex-row justify-content-between off px-3 p-2"><span>Promo code:</span><span class="code">{item.code}</span
                      >

                      </div>
                    </div>
                  </div>
                  {SelectedCoupon === item._id && <Alert message={`Apply coupon ${item.discountValue}% off`} type="success" showIcon className='coupon_fire' onClose={onClose} closable />}
                </div>
              ))}
            </div>
          </Card> : null}
      </div>
      <div className="pay_summer">
        <Card title="Payment Summary">

          <div className="pay_sum_item">
            <b>Service Amount</b>
            <b>₹ {totalAmount?.toFixed(2)}</b>
          </div>
          {applyCoupon == true ?
            <Alert showIcon={false} type="success" message=<div className="pay_sum_item">
              <p>Discount ({disValue}%)</p>
              <p>₹ {disAmount?.toFixed(2)}</p>
            </div> banner /> : null}
          <Alert showIcon={false} type="error" message=<div className="pay_sum_item">

            <p>Gst ({getGst?.detials?.gst}%)</p>
            <p>₹ {gstValue?.toFixed(2)}</p>
          </div> banner />

          <Divider dashed />
          <div className="pay_sum_item">
            <p><b>Payble Amount</b></p>
            <p><b>₹ {payAmount?.toFixed(2)}</b></p>
          </div>

        </Card>
      </div>
      <div className="paybale_amt">
        <Card bordered>
          <div className="pay_amt_item">

            <b>Amount to Pay</b>
            <b>₹ {payAmount?.toFixed(2)}</b>
          </div>
        </Card>
      </div>
    </div >
  )
}

export default PaymentSummary