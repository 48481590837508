import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import "../style/Style.css"
import { clearCart, selectCartItems } from '../redux/CartSlice';
import { addService, selectDiscount, selectDiscountAmount, selectGstAmount, selectPaybleAmount, selectServiceSlotDate, selectServiceSlotTime, selectSetting, selectTotalAmount, selectUserLocation, setServiceSlotDate, setServiceSlotTime } from '../redux/OrderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BookOurService } from '../service/APIService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../authentication/context/screenContext';

const OrderPlaced = () => {
  const dispatch = useDispatch()
  const { token } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate()
  const cartItems = useSelector(selectCartItems);
  const totalAmount = useSelector(selectTotalAmount);
  const payAmount = useSelector(selectPaybleAmount);
  const userLocation = useSelector(selectUserLocation);
  const sloteDate = useSelector(selectServiceSlotDate);
  const sloteTime = useSelector(selectServiceSlotTime);
  const settings = useSelector(selectSetting);
  const gstAmount = useSelector(selectGstAmount);
  const [count, setCount] = useState(10);
  const discountAmount = useSelector(selectDiscountAmount);


  const discountDetails = useSelector(selectDiscount)



  const bodyProduct = cartItems.map(product => {
    return {
      categoryId: product.categoryId._id,
      serviceId: product._id,
      selectedValue: product.unit,
      price: product.price
    };
  });

  console.log("bodyProduct", bodyProduct)
  console.log("totalAmount", totalAmount)

  const onFinish = async () => {
    const body = {
      userLocationId: userLocation?.detials?._id,
      serviceDate: String(sloteDate.dates),
      serviceTime: sloteTime.times,
      totalServicePrice: totalAmount,
      discountId: discountDetails?.details != null ? discountDetails?.details._id : "",
      discountAmount: Math.round(discountAmount),
      generalSettingId: settings.detials._id,
      gst: settings.detials.gst,
      gstAmount: Math.round(gstAmount),
      userPayableAmount: Math.round(payAmount),
      serviceInfo: bodyProduct

    }
    setIsLoading(true)
    try {
      await BookOurService(token, body)
        .then((res) => {
          console.log("order place", res);
          if (res.status == 201) {
            showModal()
            setIsLoading(false)
            localStorage.removeItem("cart")
            dispatch(clearCart())
            dispatch(setServiceSlotDate(null))
            dispatch(setServiceSlotTime(null))

          }

        })
        .catch((err) => {
          console.log(err);
         
        });
    } catch (error) {
      console.log(error);
    }
  };

  const showModal = () => {

    setIsModalOpen(true);

  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    let intervalId;

    if (isModalOpen) {
      intervalId = setInterval(() => {
        setCount(prevCount => {
          if (prevCount > 0) {
            return prevCount - 1; // Decrement count every second
          } else {
            clearInterval(intervalId); // Clear interval when count reaches 0
            navigate("/my-booking"); // Navigate to the booking page
            setIsModalOpen(false); // Close the modal
            return prevCount;
          }
        });
      }, 1000);
    }

    return () => clearInterval(intervalId); // Cleanup function to clear the interval
  }, [isModalOpen, setIsModalOpen, navigate]);







  return (
    <>
      <p onClick={onFinish} >
        Book Now
      </p>
      <Modal open={isModalOpen} width={300} footer={false} onOk={handleOk} closable={false} onCancel={handleCancel}>
        <div className="order_placed text-center">
          <img src='https://smart.smartshops.com.ng/wp-content/uploads/2023/03/02-lottie-tick-01-instant-2-1.gif' />
          <p>your order book successfully !</p>
          <div className="show_action_btn">

            <p >Redictect.....({count}) </p>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default OrderPlaced