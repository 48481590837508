import React, { useEffect, useState } from 'react'
import '../style/Style.css'
import Logo from '../assest/logo.png'
import { Carousel, message } from 'antd'
import { FetchHeroBanner } from '../service/APIService'
import bannerDefault from "../assest/bannerPlace.png"
import { useAuth, useScreen } from '../authentication/context/screenContext'
import Marquee from 'react-fast-marquee'
import { FaStarOfLife } from 'react-icons/fa'

function HeroSection() {
    const [banerData, setBannerData] = useState([])
    const { logout } = useAuth()
    const screenWidth = useScreen()
    const marqueeList = ["FLAT 10% OFF ON FIRST ORDER", "TRENDING DESIGN", "NAIL ART", "NAIL NOURISHING","LONG LASTING","NAIL NOURISHING"]



    const showHeroBannerList = async () => {
        try {
            await FetchHeroBanner()
                .then((res) => {
                    console.log(" banner list", res);
                    if (res.status == 200) {
                        setBannerData(res.data.data);

                    } else if (res.data.code == 283) {
                        message.error(res.data.message)
                        logout()
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (error) {
            console.log(error);

        }
    };

    useEffect(() => {
        showHeroBannerList()
    }, [])


    return (
        <section >
            <div className="hero">
                <Carousel effect="scrollx" autoplay>
                    {banerData?.length == 0 ? <img src={bannerDefault
                    } id='hero_image' /> : banerData.map((item) => (
                        <div className='hero_carousel'>
                            <img src={item.bannerImage
                            } id='hero_image' />
                            <div className="hero-container" >

                                <div className="hero-logo">
                                    {/* <img src={Logo} /> */}
                                    {
                                        window.innerWidth > 700 ?
                                            <>
                                                <div className="flate">

                                                    <h1>FLAT</h1>
                                                    <h2>10% off</h2>
                                                </div>
                                                <hr />
                                                <h4>ON FIRST SERVICE</h4>
                                            </>
                                            : ""
                                    }

                                </div>
                                <div className="hero-content" >
                                    <p> Nail Care & Nail Arts</p>
                                    <span style={{ textTransform: "capitalize" }}> {item.title}</span>
                                    <p> {item.description}</p>

                                </div>

                            </div>
                        </div>
                    ))}
                </Carousel>
                <div className='marquee-head'>
                <Marquee>
                    {
                        marqueeList.map(item => {
                            return <>
                            <div className='marq-cotainer'>
                            <div className='marq-items'>{item}</div>
                            <FaStarOfLife style={{color:"white",marginTop:"10px"}}/>
                            </div>
                            </>
                        })
                    }
                </Marquee>
                </div>
            </div>

        </section>

    )
}

export default HeroSection