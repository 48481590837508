import React from "react";
import HeroSection from "../../pages/HeroSection";
import BookingListing from "../../pages/BookingListing";
import WhyChooseUs from "../../pages/WhyChooseUs";
import HomeFaqCard from "../../pages/HomeFaqCard";
import HomeFooterCard from "../../pages/HomeFooterCard";
import MobileBottomNavigationBar from "../../pages/MobileBottomNavigationBar";
import { useWindowSize } from "react-use";

function Home() {
  const { width, height } = useWindowSize();
  return (
    <div>
      <HeroSection />
      <BookingListing />
      <WhyChooseUs />
      <HomeFaqCard />
      <HomeFooterCard />
      {width < 801 && <MobileBottomNavigationBar id={1} />}
    </div>
  );
}

export default Home;
