import React from "react";
// import "../../public/images/why_2.png";

const WhyChooseUs = () => {
  return (
    <section id="why-choose-us">
      <div className="container" data-aos="fade-up">
        <div class="section-header">
          <h2>Why Choose CuteNails?</h2>
          {/* <p>You can explore your booking using serivce category </p> */}
        </div>
        {/* <div>
          <img src="../images/why_2.png" alt="image" />
          <div>Trained and Verified Experts</div>
          <div>
            We at Yes Madam believe in working with the utmost professionalism.
            Thus, our clients are served only by well-experienced and skilled
            professionals.
          </div>
        </div> */}
        <div className="design-container">
          <div className="design-item">
            <img
              src="../images/trained.jpg"
              alt="Experts"
              className="design-image"
            />
            <h3 className="design-title">Trained and Verified Experts</h3>
            <p className="design-text">
              We at Cute Nails believe in working with the utmost
              professionalism. Thus, our clients are served only by
              well-experienced and skilled professionals.
            </p>
          </div>
          <div className="design-item">
            <img
              src="../images/ontime.jpg"
              alt="image"
              className="design-image"
            />
            <h3 className="design-title">On Time Service</h3>
            <p className="design-text">
              Our commitment to punctuality ensures that you receive your
              services right on time. We value your time and ensure that our
              professionals arrive as scheduled.
            </p>
          </div>
          <div className="design-item">
            <img
              src="../images/valuemoney.png"
              alt="Prices"
              className="design-image"
            />
            <h3 className="design-title">Transparent and Affordable Prices</h3>
            <p className="design-text">
              As we believe in 100% transparent pricing module, all our at-home
              salon and wellness services are quite affordable and
              budget-friendly.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
